import React from 'react';
import { Card, CardContent, CardHeader, Tooltip, IconButton } from '@mui/material';
import ReactSpeedometer from 'react-d3-speedometer';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Typography, Grid } from '../common';
import SpeedIcon from '@mui/icons-material/Speed';
const SpeedoMeter = ({ getCampaignWisePacing, info }) => {
  return (
    <Card
      className="w-full max-w-md mx-auto flex flex-col items-center"
      style={{ position: 'relative' }}
    >
      <Tooltip title={<Typography className="TooltipFontSize">{info}</Typography>} arrow>
        <IconButton
          aria-label="info"
          className="info-icon"
          style={{
            position: 'absolute',
            top: '8px',
            right: '8px',
            padding: '8px',
          }}
        >
          <InfoOutlinedIcon className="infoIcon" />
        </IconButton>
      </Tooltip>

      <CardHeader
        title={
          <div className="flex items-center justify-center space-x-4">
            <Typography variant="h6" align="center" className="font-weight-bold">
              {/* <ChartNoAxesCombined className="primary-color mr-3" /> */}
              <SpeedIcon className="primary-color mr-3" />
              Pacing
            </Typography>
          </div>
        }
      />
      <div className="dotted-line font20 summaryBoxBorder custom-dotted-line"></div>
      <CardContent>
        <Grid container justifyContent="center">
          <Grid item>
            <div className="flex justify-center">
              <ReactSpeedometer
                maxValue={125}
                value={getCampaignWisePacing?.pacing || 0}
                needleColor="black"
                startColor="red"
                endColor="green"
                segments={5}
                width={290}
                height={170}
                valueFormat="d"
                currentValueText={`${getCampaignWisePacing?.pacing.toFixed(0) || 0}%`}
              />
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SpeedoMeter;
