import React from 'react';
import { Card, CardContent, CardHeader, Tooltip, IconButton } from '@mui/material';
import { CircleSlider } from 'react-circle-slider';
import { Eye } from 'lucide-react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Typography } from '../common';

const ViewabilityChart = ({ getViewabilityCount, info }) => {
  const viewabilityRate = getViewabilityCount?.viewabilityCount || 0;
  const getProgressColor = (rate) => {
    if (rate >= 70) return '#4caf50'; // Green for good viewability
    if (rate >= 25) {
      const normalizedRate = (rate - 20) / 40;
      const redR = 244,
        redG = 67,
        redB = 54;
      const greenR = 76,
        greenG = 175,
        greenB = 80;
      const r = Math.round(redR + normalizedRate * (greenR - redR));
      const g = Math.round(redG + normalizedRate * (greenG - redG));
      const b = Math.round(redB + normalizedRate * (greenB - redB));

      return `rgb(${r}, ${g}, ${b})`;
    }

    return '#f44336';
  };

  const progressColor = getProgressColor(viewabilityRate);

  return (
    <Card className="w-full max-w-md mx-auto" style={{ position: 'relative' }}>
      <Tooltip
        title={
          <Typography className="TooltipFontSize">
            {' '}
            {info}
            <div>
              Note: Viewability reports are available only for campaigns that started on or after
              March 1st.
            </div>
          </Typography>
        }
        arrow
      >
        <IconButton
          aria-label="info"
          className="info-icon"
          style={{
            position: 'absolute',
            top: '8px',
            right: '8px',
            padding: '8px',
          }}
        >
          <InfoOutlinedIcon className="infoIcon" />
        </IconButton>
      </Tooltip>

      <CardHeader
        title={
          <div className="flex items-center space-x-4">
            <Typography variant="h6" align="center" className="font-weight-bold">
              <Eye className="primary-color mr-3" />
              Viewability
            </Typography>
          </div>
        }
      />

      <div className="dotted-line font20 summaryBoxBorder custom-dotted-line"></div>

      <CardContent>
        <div className="flex justify-center items-center w-full">
          <div className="text-center">
            <CircleSlider
              value={viewabilityRate}
              size={170}
              trackColor="#e0e0e0"
              progressColor={progressColor}
              circleStrokeWidth={8}
              progressWidth={8}
              circleWidth={10}
              knobRadius={10}
              showTooltip={true}
              showPercentage={true}
              disabled={true}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ViewabilityChart;
