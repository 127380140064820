import React, { useEffect, useState } from 'react';
import { NewTextField, Grid, Button, Typography } from '../../common';
import Services from '../../service-utils/services';
import { useQuery, useMutation } from 'react-query';
import queryString from 'query-string';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import OverlapLoader from '../../common/loader/OverlapLoader';
import { Paper, Switch, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';

import { getSessionData } from '../../service-utils';
import { getCurrencySymbol } from '../../common/utils';

export const Optimization = ({ payload = {}, goToNextStep, lineItemId, lineItemData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { campaignId = '', agencyId = '', advertiserId = '' } = payload;
  const { agencyCurrency, tncUpdated } = getSessionData();
  const [useSpread, setUseSpread] = useState(false);

  const { data: OptimizationData = {} } = useQuery(
    ['USER_DATA', advertiserId, campaignId, lineItemId, agencyId],
    async () => {
      const query = queryString.stringify({ agencyId });
      const response = await Services.getLineItemOptimization(
        advertiserId,
        campaignId,
        lineItemId,
        query,
      );
      return response?.data?.lineItemOptimizationDTO;
    },
    { enabled: !!agencyId },
  );
  const { data: objectiveData = [] } = useQuery(
    ['OBJECTIVE_DATA', agencyId],
    async () => {
      const response = await Services.getMasterObjectives(agencyId);
      return response?.data?.masterObjectiveList || [];
    },
    { enabled: !!agencyId },
  );

  const { mutate: updateOptimization, isLoading } = useMutation(
    async (values) => {
      const { cpm, ctr } = values;
      const payload = {
        lineItemId,
        cpm: cpm,
        ctr: ctr ? ctr.replace('%', '') : '0',
        targetedCpm: null,
        targetedCtr: null,
        useSpread: useSpread,
      };
      const query = queryString.stringify({ agencyId });
      return await Services.lineItemOptimization(
        advertiserId,
        campaignId,
        lineItemId,
        query,
        payload,
      );
    },
    {
      onError: (err) => {
        const errorMsg = err?.response?.data?.reasonDesc || 'Error in updating Optimization.';
        enqueueSnackbar(errorMsg, { variant: 'error' });
      },
      onSuccess: () => {
        enqueueSnackbar('Updated successfully.', { variant: 'success' });
        goToNextStep(lineItemId);
      },
    },
  );

  const currencyData = getCurrencySymbol(agencyCurrency);
  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      cpm: 500,
      ctr: '',
    },
    validationSchema: Yup.object({
      cpm:
        currencyData === '$'
          ? Yup.number().min(0.5, `Cpm must be greater than or equal to ${currencyData}0.5`)
          : Yup.number().min(40, `Cpm must be greater than or equal to ${currencyData}40`),
      ctr: Yup.string().max(255).required('CTR is required.'),
    }),
    onSubmit: () => updateOptimization(values),
  });

  useEffect(() => {
    if (!isEmpty(OptimizationData)) {
      setFieldValue('cpm', OptimizationData.cpm || '500');
      setFieldValue('ctr', `${OptimizationData.ctr || '0'}%`);
      setUseSpread(OptimizationData.useSpread || false);
    }
  }, [OptimizationData, setFieldValue]);

  const handleUseSpreadToggle = () => {
    setUseSpread((prev) => !prev);
  };

  const handleCTRChange = (event) => {
    const value = event.target.value.replace('%', '');
    setFieldValue('ctr', `${value}%`);
  };
  const tncLoader = tncUpdated === 'true' && isLoading;

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item size={{ xs: 12, md: 6, lg: 4 }}>
          {/* <Paper elevation={3} className="p-4 mx-auto"> */}
          <Typography color="textPrimary" variant="h5" align="center" className="mb-4">
            Optimization
          </Typography>

          <form onSubmit={handleSubmit}>
            {lineItemData?.biddingStrategy === 1 && (
              <Typography
                variant="body2"
                component="p"
                className="mb-3 text-center"
                style={{ color: 'red' }}
              >
                Note:- CPM selected for the Fixed Price bidding strategy, will be final and honoured
                for bidding on ad server. You would see the updated value here, if not updated yet.
              </Typography>
            )}

            <div className="d-flex align-items-center justify-content-between mb-3">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>Placement Spread</span>
                <Tooltip
                  title={
                    <Typography className="TooltipFontSize">
                      Placement Spread: if selected, spend will be distributed across selected
                      websites and applications.
                    </Typography>
                  }
                  arrow
                  placement="bottom"
                >
                  <IconButton aria-label="info">
                    <InfoOutlinedIcon className="infoIcon" />
                  </IconButton>
                </Tooltip>
              </div>
              <Switch checked={useSpread} onChange={handleUseSpreadToggle} color="primary" />
            </div>

            <div className="mb-3">
              <NewTextField
                required
                info="System will never spend more than provided cost for every 1000 impression"
                error={Boolean(touched.cpm && errors.cpm)}
                fullWidth
                helperText={touched.cpm && errors.cpm}
                label="CPM"
                margin="normal"
                name="cpm"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('cpm', event.target.value)}
                value={values.cpm}
                variant="outlined"
                type="number"
                inputProps={{
                  min: agencyCurrency === 'INR' ? 40 : 0.5,
                  readOnly: lineItemData?.biddingStrategy === 1,
                }}
                readOnly
                disabled={true}
              />
            </div>

            <div className="mb-4">
              <NewTextField
                required
                info="System will try to bid on placement where desired CTR can be achieved"
                error={Boolean(touched.ctr && errors.ctr)}
                fullWidth
                helperText={touched.ctr && errors.ctr}
                label="CTR"
                margin="normal"
                name="ctr"
                onBlur={handleBlur}
                onChange={handleCTRChange}
                value={values.ctr}
                variant="outlined"
                type="text"
              />
            </div>

            <div className="mb-3 d-flex justify-content-center">
              <Button
                variant="contained"
                className="btn btn-secondary d-flex"
                onClick={history.goBack}
              >
                Cancel
              </Button>
              <Button variant="contained" type="submit" className="btn btn-primary d-flex ml-4">
                {tncLoader ? 'Submitting' : 'Submit'}
              </Button>
            </div>
            {tncLoader && <OverlapLoader />}
          </form>
          {/* </Paper> */}
        </Grid>
      </Grid>
    </>
  );
};
