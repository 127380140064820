import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NewTextField, Paper, Grid, Button, Typography, NewSelect } from '../../common';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import history from '../../history';
import Services from '../../service-utils/services';
import { useQuery, useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import getSessionData from '../../service-utils/session-util';
import { handleMsgOnForm } from '../../common/utils';
import { useAdvertiser } from '../../context/AdvertiserProvider';
import OverlapLoader from '../../common/loader/OverlapLoader';
import { SGEMENT_STATUS } from '../../libs/constants';

const DeviceIdSegmentCreate = () => {
  const { deviceIdSegmentId } = useParams();
  const { advertiserId } = useAdvertiser();
  const { agencyId, tncUpdated } = getSessionData();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [serverErrors, setServerErrors] = useState({});

  useEffect(() => {
    dispatch(
      setHeader({
        back: {
          text: 'Back',
          action: () => history.goBack(),
        },
      }),
    );
  }, [dispatch]);

  const { data: deviceIdData = {}, isLoading: isLoadingDeviceIdData } = useQuery(
    ['deviceIdData', advertiserId, deviceIdSegmentId, agencyId],
    async () => {
      const payload = { agencyId };
      const response = await Services.getDeviceId(
        advertiserId,
        deviceIdSegmentId,
        queryString.stringify(payload),
      );
      return response?.data?.segmentDeviceIdDTO;
    },
    {
      enabled: !!deviceIdSegmentId && !!agencyId,
    },
  );

  const { mutate: deviceIdCreate, isLoading: isLoadingDeviceIdCreate } = useMutation(
    async (values) => {
      const { name, deviceIds, status } = values;
      const payload = {
        name,
        deviceIds,
        enabled: status.value, // Use the value from the select field
      };
      const query = queryString.stringify({ agencyId });
      const response = !!deviceIdSegmentId
        ? await Services.updateDeviceId(advertiserId, deviceIdSegmentId, query, payload)
        : await Services.createDeviceId(advertiserId, query, payload);
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
      },
      onSuccess: () => {
        enqueueSnackbar(
          !!deviceIdSegmentId
            ? 'Segment Device ID updated successfully.'
            : 'Segment Device ID created successfully.',
          { variant: 'success' },
        );
        history.push(`/advertiser/deviceId-segment`);
      },
    },
  );

  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      name: '',
      status: {},
      deviceIds: '',
    },
    enableReinitialize: true, // This allows the form to be updated when data is fetched
    validationSchema: Yup.object({
      name: Yup.string().max(255).required('Segment name is required.'),
      status: Yup.object().required('Status is required').nullable(),
    }),
    onSubmit: () => deviceIdCreate(values),
  });

  useEffect(() => {
    if (!isEmpty(deviceIdData)) {
      setFieldValue('name', deviceIdData.name || '');
      setFieldValue('deviceIds', deviceIdData.deviceIds || '');
      setFieldValue(
        'status',
        SGEMENT_STATUS.find((item) => item.value === deviceIdData.enabled) || SGEMENT_STATUS[0],
      );
    }
  }, [deviceIdData, setFieldValue]);
  const tncLoader = tncUpdated === 'true' && isLoadingDeviceIdCreate;

  return (
    <div className="col-10 p-0 bg-white rounded shadow m-auto mb-4">
      <Paper className="pt-3 p-4 mn-h-200">
        <div className="d-flex justify-content-center mb-3">
          <Typography color="textPrimary" variant="h5">
            {!!deviceIdSegmentId ? 'Edit Device Id' : 'Create New Device Id'}
          </Typography>
        </div>
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={2}
            rowSpacing={0.1}
            size={{ xs: 12, md: 12 }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewTextField
                required
                info="Name the Device Segment."
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                label="Segment Name"
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('name', event.target.value)}
                value={values.name}
                variant="outlined"
              />
              {serverErrors.name && <p className="text-danger mt-n2">{serverErrors.name}</p>}
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewSelect
                required
                info="Enable the status to start segment. Disable to pause it."
                options={SGEMENT_STATUS}
                value={values.status}
                onChange={(obj) => setFieldValue('status', obj)}
                placeholder="Status"
                className="py-2"
              />
              {serverErrors.enabled && <p className="text-danger mt-n2">{serverErrors.enabled}</p>}
            </Grid>
            <Grid item size={{ xs: 12 }} className="py-0">
              <NewTextField
                required
                fullWidth
                error={Boolean(touched.deviceIds && errors.deviceIds)}
                helperText={touched.deviceIds && errors.deviceIds}
                info="Enter the Device IDs of the areas you want to target, if required."
                label="Device Ids"
                margin="normal"
                name="deviceIds"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('deviceIds', event.target.value)}
                value={values.deviceIds}
                variant="outlined"
                multiline
                rows={3}
              />
              {serverErrors.deviceIds && (
                <p className="text-danger mt-n2">{serverErrors.deviceIds}</p>
              )}
            </Grid>
            <Typography className="ml-3">
              Device Ids should be comma-separated (e.g.
              b4dd36bc-54f2-4f13-8bd3-91ecbbb9289c,1b4dd36bc-84r2-4b13-8bd3-91ecaaa9289d, etc.)
            </Typography>
          </Grid>
          <div className="mt-4 d-flex justify-content-end">
            <Button
              variant="contained"
              className="btn btn-secondary d-flex"
              onClick={() => history.push(`/advertiser/deviceId-segment`)}
            >
              Cancel
            </Button>
            <Button variant="contained" type="submit" className="btn btn-primary d-flex ml-4">
              {!!deviceIdSegmentId
                ? tncLoader
                  ? 'Updating'
                  : 'Update'
                : tncLoader
                ? 'Creating'
                : 'Create'}
            </Button>
          </div>
          {tncLoader && <OverlapLoader />}
        </form>
      </Paper>
    </div>
  );
};

export default DeviceIdSegmentCreate;
