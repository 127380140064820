import React, { useEffect, useState } from 'react';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';
import { getSessionData } from '../../service-utils';
import { Image, SquarePlay } from 'lucide-react';

const CreativeTypeSelection = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { advertiserId } = getSessionData();
  const [selectedType, setSelectedType] = useState('BANNER');

  useEffect(() => {
    dispatch(
      setHeader({
        back: {
          text: 'Back',
          action: () => history.goBack(),
        },
        header: (
          <div className="d-flex align-items-center justify-content-between position-relative">
            Select Creative
          </div>
        ),
      }),
    );
  }, [dispatch, advertiserId]);

  const handleTypeSelection = (type) => {
    setSelectedType(type);
  };

  const handleContinue = () => {
    if (selectedType === 'BANNER') {
      history.push(`/advertiser/creative/banner`);
    } else {
      history.push(`/advertiser/creative/video`);
    }
  };

  return (
    <div className="col-md-10 bg-white rounded shadow m-auto mb-4 p-5">
      <div className="p-5">
        <div className="d-flex justify-content-center">
          <Card
            className={`m-2 text-center ${selectedType === 'BANNER' ? 'border-primary' : ''}`}
            style={{
              width: '400px',

              cursor: 'pointer',
              padding: '16px',
              borderRadius: '15px',
              border: selectedType === 'BANNER' ? '2px solid #dd6633' : '1px solid #ddd',
              boxShadow:
                selectedType === 'BANNER'
                  ? '0 4px 10px rgba(63, 81, 181, 0.3)'
                  : '0 2px 6px rgba(0, 0, 0, 0.1)',
              transition: 'box-shadow 0.3s ease',
              // backgroundColor: selectedType === 'BANNER' ? '#f5f5f5' : '#fff',
              textAlign: 'center',
            }}
            onClick={() => handleTypeSelection('BANNER')}
          >
            <Card.Body>
              <Image className="primary-color mb-2" size={28} />
              <Card.Title className="mt-2">Banner</Card.Title>
              <Card.Text>Create display ads with images and text</Card.Text>
            </Card.Body>
          </Card>

          <Card
            // disabled={true}
            className={`m-2 text-center ${selectedType === 'VIDEO' ? 'border-primary' : ''}`}
            style={{
              width: '400px',

              cursor: 'pointer',
              padding: '16px',
              borderRadius: '15px',
              border: selectedType === 'VIDEO' ? '2px solid #dd6633' : '1px solid #ddd',
              boxShadow:
                selectedType === 'VIDEO'
                  ? '0 4px 10px rgba(63, 81, 181, 0.3)'
                  : '0 2px 6px rgba(0, 0, 0, 0.1)',
              transition: 'box-shadow 0.3s ease',
              // backgroundColor: selectedType === 'VIDEO' ? '#f5f5f5' : '#fff',
              textAlign: 'center',
            }}
            onClick={() => handleTypeSelection('VIDEO')}
          >
            <Card.Body>
              <SquarePlay className="primary-color mb-2" size={28} />
              <Card.Title className=" mt-2">
                Video {/*<span className="badge bg-warning text-white ml-2">Coming Soon</span> */}
              </Card.Title>
              <Card.Text>Create engaging video ads to capture audience attention</Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="mt-4 d-flex justify-content-end">
        <Button variant="primary" onClick={handleContinue} disabled={!selectedType}>
          Continue
        </Button>
      </div>
    </div>
  );
};

export default CreativeTypeSelection;
