import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';

const Root = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
});

const DropZone = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  border: '2px dashed #dd6633',
  borderRadius: '8px',
  padding: theme.spacing(3),
  textAlign: 'center',
  cursor: 'pointer',
  backgroundColor: '#f9f9f9',
  '&:hover': {
    backgroundColor: '#f3f3f3',
  },
  width: '100%',
  maxWidth: '750px',
}));

const UploadIcon = styled(CloudUploadIcon)({
  fontSize: '48px',
  color: '#dd6633',
});

const Input = styled('input')({
  display: 'none',
});

const ErrorText = styled('p')({
  color: 'red',
  marginTop: '8px',
});

export const DragDrop = ({
  desc = '(Allowed formats are .jpg, .png, and .jpeg and size should be 216 x 96 pixels)',
  keyword = 'banner',
  acceptedFileTypes = [],
  fileTypeErrorMessage = 'File type is not supported, try with another file type.',
  getImage = () => {},
  setIsError = () => {},
  inputFileRef,
  bannerSizes,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [fileTypeError, setFileTypeError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const temp = useRef(null);
  const inputFile = inputFileRef || temp;

  const validateFile = async (file) => {
    const fileExtension = file.name.split('.').pop().toLowerCase();
    const isZip = keyword === 'html5' || keyword === 'iframe';

    if (isZip) {
      if (fileExtension !== 'zip') throw new Error('Please upload a valid ZIP file');
      // if (file.size > 10 * 1024 * 1024) throw new Error('ZIP file size should be less than 10MB');
      return file;
    } else {
      if (!acceptedFileTypes.some((type) => file.type.includes(type))) {
        throw new Error(fileTypeErrorMessage);
      }
      // if (file.size > 200 * 1024) {
      //   throw new Error('Image file size should be less than 200KB');
      // }

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (e) {
          const image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            const width = this.width;
            const height = this.height;

            if (!bannerSizes || bannerSizes.length === 0) {
              resolve(file);
              return;
            }

            const isValidDimension = bannerSizes.some((dim) => dim.key === `${width}x${height}`);

            if (isValidDimension) {
              resolve(file);
            } else {
              const errorMessage = `Check image dimensions. You selected an image with width=${width}px and height=${height}px.`;
              setErrorMsg(errorMessage);
              reject(new Error(errorMessage));
            }
          };
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const handleFileSelection = async (file) => {
    if (!file) return;

    try {
      const validFile = await validateFile(file);
      setFileTypeError(false);
      setErrorMsg('');
      setSelectedFile(validFile);
      setPreview(
        keyword !== 'html5' && keyword !== 'iframe' ? URL.createObjectURL(validFile) : null,
      );
      getImage(validFile);
      setIsError(false);
    } catch (error) {
      setFileTypeError(true);
      setErrorMsg(error.message);
      setSelectedFile(null);
      setIsError(true);
      // enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleFileSelection(e.dataTransfer.files[0]);
  };

  const onSelectFile = (e) => {
    handleFileSelection(e.target.files[0]);
  };

  const getMessageForKeyword = () => {
    switch (keyword) {
      case 'banner':
        return 'Drag & drop your image here';
      case 'html5':
      case 'iframe':
        return 'Drop your HTML5 Zip file here';
      default:
        return 'Drop your file here';
    }
  };

  const getValidationForKeyword = () => {
    switch (keyword) {
      case 'banner':
        return 'Allowed formats: .jpg, .png, .jpeg, .gif (Max size: 200KB)';
      case 'html5':
      case 'iframe':
        return 'Use ZIP format (Max size: 10MB)';
      default:
        return '';
    }
  };

  return (
    <Root>
      <DropZone
        className={classNames({ 'file-type-error': fileTypeError })}
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        onDragEnter={(e) => e.preventDefault()}
        onDragLeave={(e) => e.preventDefault()}
        onClick={() => inputFile.current.click()}
      >
        <UploadIcon />
        <p>{getMessageForKeyword()}</p>
        <p>{getValidationForKeyword()}</p>
        {desc && <p>{desc}</p>}
        {errorMsg && <ErrorText>{errorMsg}</ErrorText>}
      </DropZone>
      <Input
        ref={inputFile}
        type="file"
        accept={
          keyword === 'iframe' ? '.zip' : acceptedFileTypes.map((type) => `.${type}`).join(',')
        }
        onChange={onSelectFile}
      />
    </Root>
  );
};
