import React, { useEffect, useState, useRef } from 'react';
import { useQuery } from 'react-query';
import queryString from 'query-string';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setHeader } from '../../libs/redux-sdk/actions';
import Services from '../../service-utils/services';
import getSessionData from '../../service-utils/session-util';
import { CACHE_TIME, CONTENT_URL, STALE_TIME } from '../../common/constants';
import { Paper, Grid, NewSelect } from '../../common';
import { useHistory } from 'react-router-dom';
import { DragDrop } from '../../components/uploadFile/DragDrop';
import ViewCreative from '../view-creatives/view-creatives';
import { Dialog, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material';
import { Eye, X } from 'lucide-react';
import { isEmpty } from 'lodash';

const FILE_TYPE = {
  banner: 'Image/png, Image/jpeg, Image/jpg, Image/gif',
  html5: 'zip',
};

const BANNER_TYPES = [
  {
    value: 2,
    label: 'Banner adv',
    name: 'banner',
  },
  {
    value: 4,
    label: 'HTML 5 Adv',
    name: 'iframe',
  },
];

export const BannerUpload = () => {
  const { creativeId } = useParams();
  const { agencyId, advertiserId } = getSessionData();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const inputFile = useRef(null);
  const [bannerSizeOptions, setBannerSizeOptions] = useState([]);

  const [fileType, setFileType] = useState('banner');
  const [file, setFile] = useState(null);
  const [selectedCreative, setSelectedCreative] = useState(
    BANNER_TYPES.find((type) => type.name === 'banner'),
  );
  const [isError, setIsError] = useState(false);
  const [bannerType, setBannerType] = useState(BANNER_TYPES[0].label);
  const [dimension, setDimension] = useState();

  const [serverErrors, setServerErrors] = useState({});
  const [bannerTypeId, setBannerTypeId] = useState(BANNER_TYPES[0].value);
  const [newCreativeId, setNewCreativeId] = useState();
  const history = useHistory();
  const [refresh, setRefresh] = useState(false);
  const [openDialog, setOpenDialog] = useState(false); // State for the popup

  const { data: creativeData = {} } = useQuery(
    ['CREATIVE_DATA', creativeId, agencyId, advertiserId],
    async () => {
      const payload = { agencyId };
      const response = await Services.creativeGetDetails(
        advertiserId,
        creativeId,
        queryString.stringify(payload),
      );
      return response.data?.creativeDTO;
    },
    { enabled: !!creativeId && !!agencyId },
  );
  const { data: bannerSizes = [] } = useQuery(
    ['MASTER_BANNER_SIZES', agencyId],
    async () => {
      const payload = { agencyId };
      const response = await Services.getMasterBannerSizes(queryString.stringify(payload));
      return response.data?.masterList;
    },
    { enabled: !!agencyId, staleTime: STALE_TIME.HALF_HOUR, cacheTime: CACHE_TIME.HALF_HOUR },
  );

  useEffect(() => {
    if (!isEmpty(bannerSizes)) {
      const data = bannerSizes.map((item) => ({
        ...item,
        value: item.id,
      }));
      setBannerSizeOptions(data);
    }
  }, [bannerSizes]);

  useEffect(() => {
    dispatch(
      setHeader({
        back: {
          text: 'Back',
          action: () => history.goBack(),
        },
        header: (
          <div className="d-flex align-items-center justify-content-between position-relative">
            Creative Upload
          </div>
        ),
      }),
    );
  }, [dispatch]);

  const handleBannerTypeChange = (selectedOption) => {
    setSelectedCreative(selectedOption);
    setFileType(selectedOption.name);
    setBannerType(selectedOption.label);
    setBannerTypeId(selectedOption.value);
  };

  const uploadImage = () => {
    if (!file || !file?.type) {
      enqueueSnackbar('No file selected', { variant: 'error' });
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    if (creativeId || newCreativeId) {
      Services.updateCreativeUpload(
        advertiserId,
        creativeId || newCreativeId,
        bannerTypeId,
        queryString.stringify({ agencyId, dimension }),
        formData,
      )
        .then((response) => {
          enqueueSnackbar('File uploaded successfully', { variant: 'success' });
          setFile(null);
          setNewCreativeId(response.data?.creativeDTO?.id);
          setRefresh((prev) => !prev);
        })
        .catch((error) => {
          const errorData = error?.response?.data?.reasonDesc;
          enqueueSnackbar(errorData, { variant: 'error' });
          setIsError(true);
        });
    } else {
      Services.creativeUpload(
        advertiserId,
        bannerTypeId,
        queryString.stringify({ agencyId, dimension }),
        formData,
      )
        .then((response) => {
          enqueueSnackbar('File uploaded successfully', { variant: 'success' });
          setFile(null);
          setNewCreativeId(response.data?.creativeDTO?.id);
          history.push(`/advertiser/creative-banner/${response.data?.creativeDTO?.id}`);
        })
        .catch((error) => {
          const errorData = error?.response?.data?.reasonDesc;
          enqueueSnackbar(errorData, { variant: 'error' });
          setIsError(true);
        });
    }
  };

  useEffect(() => {
    if (!isError && file) {
      uploadImage();
    }
  }, [file, isError]);

  useEffect(() => {
    if (creativeData) {
      if (creativeData?.bannerDTO?.adType === BANNER_TYPES[0].value) {
        setFileType('banner');
      } else if (creativeData?.bannerDTO?.adType === BANNER_TYPES[1].value) {
        setFileType('iframe');
      }
    }
  }, [creativeData]);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);
  let height = creativeData?.bannerDTO?.masterBannerSizeDTO?.height;
  let width = creativeData?.bannerDTO?.masterBannerSizeDTO?.width;

  return (
    <>
      <div className="col-md-10 bg-white rounded shadow m-auto p-0">
        {!creativeData.published && (
          <Paper className=" p-4 mn-h-200">
            {newCreativeId == null && creativeId == null && (
              <Grid item size={{ xs: 12, sm: 12 }} className="py-0 ">
                <Grid container spacing={2} alignItems="center">
                  <Grid item size={{ xs: 12, sm: 3 }} className="">
                    <NewSelect
                      required
                      options={BANNER_TYPES}
                      value={selectedCreative}
                      onChange={(selectedOption) => {
                        setSelectedCreative(selectedOption);
                        handleBannerTypeChange(selectedOption);
                      }}
                      getOptionValue={(option) => option.value}
                      getOptionLabel={(option) => option.label}
                      placeholder="Banner Type"
                      className="py-2"
                      isDisabled={!!creativeId || !!newCreativeId}
                    />
                    {serverErrors.bannerType && (
                      <p className="text-danger mt-n2">{serverErrors.bannerType}</p>
                    )}
                  </Grid>

                  {fileType === 'iframe' && (
                    <Grid item size={{ xs: 12, sm: 3 }} className="">
                      <NewSelect
                        info="Select the appropriate size of your banner from the available list"
                        options={bannerSizeOptions}
                        value={bannerSizeOptions.find((option) => option.key === dimension)}
                        onChange={(selectedOption) => setDimension(selectedOption.key)}
                        placeholder="Banner size"
                        className="py-2"
                        disabled={!!creativeId}
                        isOptionDisabled={() => !!creativeId}
                      />

                      {serverErrors.banner && (
                        <p className="text-danger mt-n2">{serverErrors.banner}</p>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}

            <Grid container spacing={1}>
              <Grid
                item
                size={{ xs: creativeData?.bannerDTO?.bannerUrl ? 9 : 12 }}
                className=" mt-2"
              >
                <DragDrop
                  key={`${fileType}-${refresh}`}
                  accept={fileType === 'iframe' ? 'application/zip' : FILE_TYPE[fileType]}
                  desc=""
                  acceptedFileTypes={['png', 'jpeg', 'jpg', 'zip']}
                  getImage={(newFile) => {
                    setFile(newFile);
                    setIsError(false);
                  }}
                  disabled={creativeId}
                  fileDimensions="0x0"
                  inputFileRef={inputFile}
                  bannerSizes={bannerSizes}
                  keyword={fileType}
                />
              </Grid>
              {creativeData?.bannerDTO?.bannerUrl && (
                <Grid
                  item
                  size={{ xs: 3 }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <div className="col-md-2 d-flex align-items-center justify-content-center">
                    <Tooltip
                      title={<Typography className="TooltipFontSize">Preview Banner</Typography>}
                    >
                      <IconButton
                        onClick={handleOpenDialog}
                        disabled={!creativeData?.bannerDTO?.bannerUrl}
                        className="d-flex align-items-center"
                      >
                        <Eye className="me-2 primary-color" size={23} />
                        <span className="primary-color ml-2">View</span>
                      </IconButton>
                    </Tooltip>
                  </div>
                </Grid>
              )}
            </Grid>
          </Paper>
        )}
      </div>
      <div className="mt-3">{(creativeId || newCreativeId) && <ViewCreative key={refresh} />}</div>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth={false}
        PaperProps={{
          style: {
            width: width || 'auto',
            minWidth: '1000px',
            maxWidth: '100%',
            height: `${parseInt(height) + 200}px`, // Adds 100px to the current height
            margin: 'auto',
            padding: '10px',
          },
        }}
      >
        <DialogTitle>
          Image Preview
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'grey',
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        {creativeData?.bannerDTO?.adType === 4 && (
          <Typography
            variant="body2"
            style={{
              marginTop: '10px',
              color: 'grey',
              textAlign: 'center',
            }}
          >
            Note:- Make sure the creative fits within the preview dimensions
          </Typography>
        )}
        <DialogContent
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: height,
          }}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{
              height: creativeData?.bannerDTO?.masterBannerSizeDTO?.height || height || 'auto',
              width: creativeData?.bannerDTO?.masterBannerSizeDTO?.width || width || 'auto',
            }}
          >
            {creativeData?.bannerDTO?.adType === 4 ? (
              <iframe
                src={`${CONTENT_URL}/${creativeData?.bannerDTO?.bannerUrl}`}
                title="creative"
                scrolling="no"
                // className="rounded-sm"
                style={{
                  height: height || '100%',
                  width: width || '100%',
                  border: '2px solid black',
                  boxSizing: 'border-box',
                  display: 'block',
                  margin: 'auto',
                }}
              />
            ) : (
              <img
                className="rounded-sm"
                src={`${CONTENT_URL}/${creativeData?.bannerDTO?.bannerUrl}`}
                alt="banner"
                style={{
                  border: '2px solid black',
                  padding: '10px',
                  display: 'block',
                  margin: 'auto',
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
