import React, { useEffect, useState, useRef } from 'react';
import { useQuery } from 'react-query';
import queryString from 'query-string';
import { useSnackbar } from 'notistack';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setHeader } from '../../libs/redux-sdk/actions';
import Services from '../../service-utils/services';
import getSessionData from '../../service-utils/session-util';
import { Paper, Grid, NewSelect } from '../../common';
import { DragDropVideo } from '../../components/uploadFile/DragDropVideo';
import { Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import { Eye, X } from 'lucide-react';
import ViewVideoCreative from '../view-video-creative/view-video-creative';
import { CACHE_TIME, CONTENT_URL, STALE_TIME } from '../../common/constants';
import OverlapLoader from '../../common/loader/OverlapLoader';

const VideoUpload = () => {
  const { creativeId } = useParams();
  const { agencyId, advertiserId, tncUpdated } = getSessionData();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const inputFile = useRef(null);
  const history = useHistory();
  const [file, setFile] = useState(null);
  const [selectedCreative, setSelectedCreative] = useState();
  const [newCreativeId, setNewCreativeId] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false); // Loader state

  const { data: creativeData = {} } = useQuery(
    ['CREATIVE_DATA', creativeId, agencyId, advertiserId],
    async () => {
      const response = await Services.creativeGetDetails(
        advertiserId,
        creativeId,
        queryString.stringify({ agencyId }),
      );
      return response.data?.creativeDTO || {};
    },
    { enabled: Boolean(creativeId) && Boolean(agencyId) },
  );
  // Fetch mime types
  const { data: mimeTypes = {} } = useQuery(
    ['MASTER_MIME_TYPES', agencyId],
    async () => {
      const payload = { agencyId };
      const response = await Services.getMasterMimeTypes(queryString.stringify(payload));
      return response.data?.masterList;
    },
    { enabled: !!agencyId, staleTime: STALE_TIME.HALF_HOUR, cacheTime: CACHE_TIME.HALF_HOUR },
  );
  useEffect(() => {
    if (mimeTypes?.length > 0) {
      const defaultOption = mimeTypes.find((option) => option.id === 1);
      setSelectedCreative(defaultOption || mimeTypes[0]);
    }
  }, [mimeTypes]);

  useEffect(() => {
    dispatch(
      setHeader({
        back: { text: 'Back', action: () => history.goBack() },
        header: <div className="d-flex align-items-center">Video Upload</div>,
      }),
    );
  }, [dispatch, history]);

  const uploadVideo = async (newFile) => {
    if (!newFile?.type) {
      enqueueSnackbar('No file selected', { variant: 'error' });
      return;
    }
    if (!selectedCreative) {
      enqueueSnackbar('Please select a video format', { variant: 'error' });
      return;
    }
    setLoading(true); // Show loader before upload starts

    const formData = new FormData();
    formData.append('file', newFile);

    try {
      const response =
        creativeId || newCreativeId
          ? await Services.updateVideoUpload(
              advertiserId,
              creativeId || newCreativeId,
              selectedCreative.id,
              queryString.stringify({ agencyId }),
              formData,
            )
          : await Services.videoUpload(
              advertiserId,
              selectedCreative.id,
              queryString.stringify({ agencyId }),
              formData,
            );

      enqueueSnackbar('Video uploaded successfully', { variant: 'success' });
      setFile(null);
      history.push(`/advertiser/creative-video/${response.data?.creativeDTO?.id}`);

      if (response.data?.creativeDTO?.id) setNewCreativeId(response.data.creativeDTO.id);
      setRefresh((prev) => !prev);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.reasonDesc || 'Upload failed', { variant: 'error' });
    } finally {
      setLoading(false); // Hide loader after upload
    }
  };

  const handleFileUpload = (newFile) => {
    setFile(newFile);
    uploadVideo(newFile);
  };

  const getAcceptString = () => selectedCreative?.name || 'video/mp4';
  const getAcceptedFileTypes = () => {
    switch (selectedCreative?.value) {
      case 2:
        return ['swf'];
      case 3:
        return ['js'];
      default:
        return ['mp4'];
    }
  };
  const getDescription = () => {
    switch (selectedCreative?.value) {
      case 2:
        return 'Upload a Flash file (SWF)';
      case 3:
        return 'Upload a JavaScript file (JS)';
      default:
        return 'Upload a video file (MP4)';
    }
  };
  const tncLoader = tncUpdated === 'true' && loading;

  return (
    <>
      <div className="col-md-10 bg-white rounded shadow m-auto p-0">
        {!creativeData.published && (
          <Paper className=" p-4 mn-h-200">
            <Grid container spacing={2} alignItems="center">
              {!creativeId && (
                <Grid item size={{ xs: 12, sm: 3 }}>
                  <NewSelect
                    required
                    options={mimeTypes}
                    value={selectedCreative}
                    onChange={setSelectedCreative}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.label}
                    placeholder="Select Format"
                    className="py-2"
                    isOptionDisabled={(option) => option.id === 2 || option.id === 3}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={1} className="mt-2">
              <Grid item size={{ xs: creativeData?.videoDTO?.uploadedVideoUrl ? 9 : 12 }}>
                <DragDropVideo
                  key={`${refresh}-${selectedCreative?.value || 'default'}`}
                  accept={getAcceptString()}
                  desc={getDescription()}
                  acceptedFileTypes={getAcceptedFileTypes()}
                  getImage={handleFileUpload}
                  // disabled={!!creativeId}
                  inputFileRef={inputFile}
                />
              </Grid>
              {creativeData?.videoDTO?.uploadedVideoUrl && (
                <Grid
                  item
                  size={{ xs: 3 }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <Tooltip title="Preview Video">
                    <IconButton onClick={() => setOpenDialog(true)}>
                      <Eye className="primary-color" size={23} />
                      <span className="primary-color ml-2">View</span>
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Paper>
        )}
      </div>
      <div className="mt-3">
        {(creativeId || newCreativeId) && <ViewVideoCreative key={refresh} />}
      </div>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth={false}>
        <DialogTitle>
          Video Preview
          <IconButton
            onClick={() => setOpenDialog(false)}
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <X />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {creativeData?.videoDTO?.uploadedVideoUrl && (
            <video controls width="400px">
              <source
                src={`${CONTENT_URL}/${creativeData.videoDTO.uploadedVideoUrl}`}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          )}
        </DialogContent>
      </Dialog>
      {tncLoader && <OverlapLoader />}
    </>
  );
};

export default VideoUpload;
