import React, { useEffect, useState, useMemo } from 'react';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import { Table } from '../../components';
import { Grid, Loader } from '../../common';
import getSessionData from './../../service-utils/session-util';
import { useHistory, useLocation } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import { Dropdown } from 'react-bootstrap';
import Highcharts from 'highcharts';
import SpeedIcon from '@mui/icons-material/Speed';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Services from '../../service-utils/services';
import { useQuery, useMutation } from 'react-query';
import queryString from 'query-string';
import { LINE_ITEM_BIDDING_STRATEGY } from '../../libs/constants';
import { handleMsgOnForm, getCurrencySymbol } from '../../common/utils';
import { ConformationBox } from '../../components/conformationBox';
import { useSnackbar } from 'notistack';
import bullet from 'highcharts/modules/bullet.js';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import spendIcon from '../../assets/icon/spend.png';
import { GetCampaignData } from './../../service-utils/useApis';
import { numberFormatter } from './../../common/utils';
import './line-item.scss';
import { useAdvertiser } from '../../context/AdvertiserProvider';
import { IconButton, Tooltip } from '@mui/material';
import CreateIcon from '../../components/create-icon/createIcon';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { CloneButton, DeleteButton, EditButton } from '../../components/react-icons/ReactIcons';
import SpeedoMeter from '../../components/SpeedoMeter';
import GroupIcon from '@mui/icons-material/Group';
import { isEmpty } from 'lodash';

bullet(Highcharts);

export const LineItem = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [deleteId, setDeleteId] = useState();
  //const [searchText,setSearchText] = useState('')
  const queryParams = queryString.parse(location.search);
  const { campaignId } = queryParams;
  let { agencyId, agencyCurrency } = getSessionData();
  const { advertiserId } = useAdvertiser();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const [serverErrors, setServerErrors] = useState({});
  const { data: campaignData = {} } = GetCampaignData(
    campaignId,
    advertiserId,
    agencyId,
    agencyCurrency,
  );

  const { data: campaignUniqueReach } = useQuery(
    ['CAMPAIGN_UNIQUE_DATA', agencyId, campaignId],
    async () => {
      const queryStringParams = {
        advertiserId,
        campaignId: campaignId,
      };
      const response = await Services.getCampaignUniqueReach(
        agencyId,
        queryString.stringify(queryStringParams),
      );
      return response?.data?.campaignAnalyticsDTO;
    },
    { enabled: !!agencyId && !isEmpty(campaignId) },
  );

  const { data: getCampaignWisePacing } = useQuery(
    ['CAMPAIGN_PACING_DATA', agencyId, campaignId],
    async () => {
      const response = await Services.getCampaignWisePacing(campaignId);
      return response?.data?.pacingDTO;
    },
    { enabled: !!agencyId && !isEmpty(campaignId) },
  );

  const { data, isLoading, refetch } = useQuery(
    ['LINE_ITEM_DATA', pageLimit, pageNumber, agencyId],
    async () => {
      const queryStringParams = {
        pageNum: pageNumber - 1,
        pageSize: pageLimit,
        agencyCurrency,
        agencyId,
      };
      const response = await Services.lineItemSearch(
        advertiserId,
        campaignId,
        queryString.stringify(queryStringParams),
      );
      return response.data;
    },
    { enabled: !!agencyId && !!campaignId },
  );

  const { mutate: handleDelete } = useMutation(
    async (id) => {
      const query = queryString.stringify({ agencyId });
      const response = await Services.lineItemDelete(id, query);
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
        setDeleteId();
      },
      onSuccess: () => {
        enqueueSnackbar('Line item deleted successfully.', { variant: 'success' });
        setDeleteId();
        refetch();
      },
    },
  );

  const tableData = useMemo(() => {
    return data?.lineItemDTO || [];
  }, [data]);

  // const handleAddLineItem = () => {
  //   const endDateTime = campaignData?.endDateTime;
  //   const dateParts = endDateTime.split(' ');
  //   const [day, month, year] = dateParts[0].split('/');
  //   const [hours, minutes] = dateParts[1].split(':');
  //   const campaignEndDate = new Date(year, month - 1, day, hours, minutes);
  //   const currentDate = new Date();

  //   if (campaignEndDate < currentDate) {
  //     enqueueSnackbar(
  //       'This campaign has ended. Please create a new campaign to create new Line Item.',
  //       {
  //         variant: 'warning',
  //       },
  //     );
  //   } else {
  //     history.push(
  //       `/advertiser/line-item-create?advertiserId=${advertiserId}&campaignId=${campaignId}&timezone=${campaignData?.timezone}`,
  //     );
  //   }
  // };

  useEffect(() => {
    dispatch(
      setHeader({
        back: {
          text: 'Back',
          action: () => history.goBack(),
        },
        header: (
          <div className="d-flex align-items-center justify-content-between position-relative">
            Line Items
          </div>
        ),
      }),
    );
  }, [dispatch]);

  let eCPC = campaignData.totalClick
    ? campaignData.totalSpent?.amount / campaignData.totalClick
    : 0;
  let eCPM = campaignData.impressionsDelivered
    ? (campaignData.totalSpent?.amount * 1000) / campaignData.impressionsDelivered
    : 0;

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPageLimit(sizePerPage);
    const updatedParams = {
      ...queryString.parse(location.search),
      pageNum: page - 1, // adjust for 0-based index
      pageSize: sizePerPage,
    };
    history.replace({ search: queryString.stringify(updatedParams) });
  };
  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    const parsedPageNum = Number(queryParams.pageNum);
    if (!isNaN(parsedPageNum) && parsedPageNum + 1 !== pageNumber) {
      setPageNumber(parsedPageNum + 1);
    }
    setPageLimit(Number(queryParams.pageSize) || 10);
  }, [location.search]);

  const handleEdit = (id) => {
    if (id) {
      history.push(
        `/advertiser/line-item/${id}?advertiserId=${advertiserId}&campaignId=${campaignId}&isEdit=true#0`,
      );
    }
  };
  const handleClone = (id) => {
    if (id) {
      history.push(`/advertiser/line-item-clone/${id}?campaignId=${campaignId}`);
    }
  };

  const manageButton = (row) => {
    return (
      <>
        <Dropdown id="dropdown-item-button" className="action-dropdown btn-action ">
          <Dropdown.Toggle variant="link">
            <MoreHorizIcon />
          </Dropdown.Toggle>
          <Dropdown.Menu className="shadow-lg">
            <Dropdown.Item onClick={() => handleClone(row.id)}>
              <CloneButton handleApply={() => handleClone(row.id)} />{' '}
              <span className="primary-color">Clone</span>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleEdit(row.id)}>
              <EditButton handleEdit={() => handleEdit(row.id)} />{' '}
              <span className="primary-color">Edit</span>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setDeleteId(row.id)}>
              <DeleteButton handleDelete={() => setDeleteId(row.id)} />{' '}
              <span className="deleteColor">Delete</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  const handleOpen = () => {
    // setOpen(true);
    history.push(
      `/advertiser/line-item-create?advertiserId=${advertiserId}&campaignId=${campaignId}&timezone=${campaignData?.timezone}`,
    );
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const handleIcons = (row) => {
  //   const { id } = row;
  //   return (
  //     <div className="flex items-center gap-1">
  //       <CloneButton handleApply={() => handleClone(id)} />
  //       <EditButton handleEdit={() => handleEdit(id)} />
  //       <DeleteButton handleDelete={() => setDeleteId(id)} />
  //     </div>
  //   );
  // };
  // const SummaryBox = (props) => {
  //   const { title = '', icon, image, value = '', valuesData, titles, tooltipValue } = props;
  //   return (
  //     <Card className="d-flex p-2">
  //       <div className="d-flex w-100 align-items-center">
  //         <div className="w-25 summary-icon">{icon ? icon : <img alt="image" src={image} />}</div>
  //         <div className="w-75">
  //           <Tooltip title={<Typography className="TooltipFontSize">{tooltipValue}</Typography>}>
  //             <CardContent className="p-2 mr-5">
  //               <div className="filters d-flex justify-content-between">
  //                 <Typography className="mt-1 font20 summarytitleColor">{title || ''}</Typography>
  //                 <Typography className="mr-2 h4 font30">{value || ''}</Typography>
  //               </div>

  //               <div className="dotted-line font20 summaryBoxBorder"></div>
  //               <div className="filters d-flex justify-content-between">
  //                 <Typography className="h4 mt-1 font30">{valuesData || ''}</Typography>
  //                 <Typography className="mt-2 font20 summarytitleColor">{titles || ''}</Typography>
  //               </div>
  //             </CardContent>
  //           </Tooltip>
  //         </div>
  //       </div>
  //     </Card>
  //   );
  // };
  const SummaryBox = (props) => {
    const {
      title = '',
      icon,
      image,
      value = 0,
      valuesData = '',
      titles = '',
      tooltipValue = '',
      infoIconData = '',
      isUnique,
    } = props;

    return (
      <Card className="d-flex p-2 summary-box">
        <div
          className="d-flex w-100 align-items-center"
          style={{ height: '112px', position: 'relative' }}
        >
          <div className="w-25 summary-icon">{icon ? icon : <img alt="image" src={image} />}</div>

          <div className="w-75">
            <Tooltip title={<Typography className="TooltipFontSize">{tooltipValue}</Typography>}>
              <CardContent className="p-2 mr-5">
                {isUnique === 'true' ? (
                  <div className="filters d-flex justify-content-between mb-">
                    <Typography className="mt-1 font20 summarytitleColor">{title || ''}</Typography>
                    <Typography className="mr-2 h4 font30">{value || ''}</Typography>
                  </div>
                ) : (
                  <div className="filters d-flex justify-content-between mb-2">
                    <Typography className="mt-1 font20 summarytitleColor">{title || ''}</Typography>
                    <Typography className="mr-2 h4 font30">{value || ''}</Typography>
                  </div>
                )}
                {isUnique === 'true' && (
                  <Typography
                    className="summarytitleColor text-right mb-1"
                    style={{ fontSize: '11px' }}
                  >
                    Per Campaign
                  </Typography>
                )}

                <div className="dotted-line font20 summaryBoxBorder"></div>
                <div className="filters d-flex justify-content-between mt-2">
                  <Typography className="h4 mt-1 font30">{valuesData || ''}</Typography>
                  <Typography className="mt-2 font20 summarytitleColor">{titles || ''}</Typography>
                </div>
              </CardContent>
            </Tooltip>
          </div>

          <Tooltip
            title={<Typography className="TooltipFontSize">{infoIconData}</Typography>}
            arrow
          >
            <IconButton
              aria-label="info"
              className="info-icon"
              style={{
                position: 'absolute',
                top: '0px',
                right: '0px',
              }}
            >
              <InfoOutlinedIcon className="infoIcon" />
            </IconButton>
          </Tooltip>
        </div>
      </Card>
    );
  };

  const getTableHeader = [
    //{ dataField: 'id', text: 'ID' },

    {
      dataField: '',
      formatter: (col, row) => (
        <div style={{ position: 'absolute', top: 0 }}>
          {row.expired && <div className="cross-tape">Expired</div>}
        </div>
      ),
    },
    {
      dataField: 'name',
      text: <div className="ml-3">Line Item</div>,
      formatter: (col, row) => (
        <div>
          <a
            href={`/advertiser/line-item/${row.id}?advertiserId=${advertiserId}&campaignId=${campaignId}&isEdit=true#0`}
          >
            {col}
          </a>
        </div>
      ),
    },
    {
      dataField: 'pacing',
      text: 'Current Pacing',
      formatter: (col) => (
        <div>
          <SpeedIcon />
          <span className="ml-2">{col || 0}</span>
        </div>
      ),
    },
    {
      dataField: 'biddingStrategy',
      text: 'Bidding Strategy',
      formatter: (col) => LINE_ITEM_BIDDING_STRATEGY.filter((item) => item.value === col)[0]?.label,
    },
    { dataField: 'startDateTime', text: 'Start Date' },
    { dataField: 'endDateTime', text: 'End Date' },
    // { dataField: 'timezone', text: 'Timezone' },
    {
      dataField: 'impressionGoal',
      text: 'Impression Goal',
      formatter: (col) => (col !== null ? col : '-'),
    },
    {
      dataField: 'spendGoal',
      text: 'Spend Goal',
      formatter: (col) => (col !== null ? col : '-'),
    },

    {
      dataField: 'deliveredImpressions',
      text: 'Delivered Impressions',
      formatter: (col) => (col !== null ? col : '0'),
    },
    {
      dataField: 'deliveredSpend',
      text: 'Delivered Spend',
      formatter: (col) => (col !== null ? col.toFixed(2) : '0'),
    },
    { dataField: 'cpm', text: 'CPM', formatter: (col) => (col !== null ? col : '-') },
    {
      dataField: 'enabled',
      text: 'Status',
      formatter: (col, row) =>
        col ? (
          <Chip label="Enabled" className="alert-success" />
        ) : (
          <Chip label="Disabled" className="alert-danger" />
        ),
    },
    { dataField: 'id', text: '', formatter: (col, row) => manageButton(row) },
    // { dataField: 'id', text: '', formatter: (col, row) => handleIcons(row) },
  ];
  //   function kFormatter(num) {
  //     return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
  // }
  const handleButtonClick = () => {
    handleOpen(true);
  };

  return (
    <>
      <div className="mb-3 d-flex justify-content-between filters">
        {/*
      <SearchInput onChange={(e) => {setSearchText(e.target.value)}} value={searchText}/>
        <Button variant="contained" className="btn btn-primary d-flex btn-38 ml-3" startIcon={<FilterListIcon />}
        onClick={handleApply}>
          Apply
        </Button>
      */}
        <div className="w-75">
          <div class="col inline-left-div">
            {' '}
            <h3>Selected Campaign :</h3>
            <span class="campaign-name">{campaignData?.name && `${campaignData?.name}`}</span>
          </div>
          <div class="col inline-left-div">
            <h3>Start/End Date :</h3>
            <span class="campaign-name">
              {campaignData?.startDateTime &&
                campaignData?.endDateTime &&
                ` ${campaignData?.startDateTime} - ${campaignData?.endDateTime} `}
            </span>
          </div>
          <div class="col inline-left-div">
            <h3>Zone :</h3>
            <span class="campaign-name">
              {campaignData?.timezone && `${campaignData?.timezone}`}
            </span>
          </div>
          {/* {campaignData?.conversionTracker ? (
            <div className="col inline-left-div mt-2 cursor-pointer ">
              <h3 onClick={() => handleConversionTracking(campaignId)}>
                <InfoIcon
                  onClick={() => handleConversionTracking(campaignId)}
                  className="primary-color"
                />{' '}
                Conversion Tracking
              </h3>
            </div>
          ) : null} */}
        </div>

        {/* <button style={{ height: '40px' }} className="btn btn-primary" onClick={handleOpen}>
          {/* <AddIcon /> */}
        {/* New Line Item */}
        {/* </button> */}
        <div>
          <CreateIcon label="Create New Line Item" handleAdd={handleOpen} />
        </div>

        {/* <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ObjectivePopUp
            campaignId={campaignId}
            open={open}
            handleClose={handleClose}
            advertiserId={advertiserId}
            agencyId={agencyId}
            timezone={campaignData?.timezone}
            currency={agencyCurrency}
          />
        </Modal> */}
      </div>
      <br />
      <div className="dashboard-container1 mb-4">
        <Grid container spacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item size={{ xs: 12, md: 8 }}>
            <Grid container spacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item size={{ xs: 12, sm: 6 }}>
                <SummaryBox
                  title="Impressions"
                  icon={<RemoveRedEyeIcon className="font50 primary-color" />}
                  value={`${numberFormatter(campaignData?.impressionsDelivered || 0)}`}
                  valuesData={`${getCurrencySymbol(
                    campaignData?.totalSpent?.currency,
                  )} ${numberFormatter(eCPM)}`}
                  titles="eCPM"
                  tooltipValue={`Impressions: ${campaignData?.impressionsDelivered || 0}`}
                  infoIconData={`Impressions refer to the number of times an ad is displayed on a user's screen. Each time the ad appears, it counts as one impression, regardless of whether the user interacts with it or not`}
                />
              </Grid>

              <Grid item size={{ xs: 12, sm: 6 }}>
                <SummaryBox
                  title="Clicks"
                  icon={<TouchAppIcon className="font50 primary-color" />}
                  value={` ${numberFormatter(campaignData?.totalClick || 0)}`}
                  valuesData={`${campaignData?.ctr !== undefined ? `${campaignData.ctr}%` : '0%'}`}
                  titles="CTR"
                  tooltipValue={`Clicks: ${campaignData?.totalClick || 0}`}
                  infoIconData={`Clicks refer to the number of times users interact with an ad by clicking on it. This typically redirects them to the advertiser's website, app, or landing page.`}
                />
              </Grid>

              <Grid item size={{ xs: 12, sm: 6 }}>
                <SummaryBox
                  title="Total Spent"
                  image={spendIcon}
                  value={
                    `${getCurrencySymbol(campaignData?.totalSpent?.currency)} ${numberFormatter(
                      campaignData?.totalSpent?.amount,
                    )}` || 0
                  }
                  valuesData={
                    `${getCurrencySymbol(campaignData?.totalSpent?.currency)} ${numberFormatter(
                      eCPC,
                    )}` || 0
                  }
                  titles="eCPC"
                  tooltipValue={
                    `Total Spent: ${getCurrencySymbol(campaignData?.totalSpent?.currency)} ${
                      campaignData?.totalSpent?.amount
                    }` || 0
                  }
                  infoIconData={`Total Spent refers to the total amount of money spent on a campaign or specific ad over a given period.`}
                />
              </Grid>
              <Grid item size={{ xs: 12, sm: 6 }}>
                <SummaryBox
                  title="Unique reach"
                  icon={<GroupIcon className="font50 primary-color" />}
                  value={`${numberFormatter(campaignUniqueReach?.uniqueReach)}`}
                  infoIconData={`Unique Reach refers to the number of individual users (or unique devices) who have seen an ad at least once during a campaign, without counting repeated views by the same user. It helps advertisers measure the true size of their audience.`}
                  tooltipValue={`Total Unique reach: ${campaignUniqueReach?.uniqueReach || 0}`}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item size={{ xs: 12, md: 4 }}>
            <div className="">
              <SpeedoMeter
                getCampaignWisePacing={getCampaignWisePacing}
                info={'Control the delivery speed of your campaign to match your budget and goals.'}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      {/* <div className="summary-box mt-0 mb-3">
        <Grid container spacing={3}>
          <Grid item size={{ xs: 4 }} className="d-grid ">
            <SummaryBox
              title="Impressions"
              icon={<RemoveRedEyeIcon className="font50 primary-color" />}
              value={`${numberFormatter(campaignData?.impressionsDelivered || 0)}`}
              valuesData={
                `${getCurrencySymbol(campaignData?.totalSpent?.currency)} ${numberFormatter(
                  eCPM,
                )}` || 0
              }
              titles="eCPM"
              tooltipValue={`Impressions: ${campaignData?.impressionsDelivered || 0}`}
            />
          </Grid>
          <Grid item size={{ xs: 4 }} className="d-grid">
            <SummaryBox
              title="Clicks"
              icon={<TouchAppIcon className="font50 primary-color" />}
              value={` ${numberFormatter(campaignData?.totalClick || 0)}`}
              valuesData={`${campaignData?.ctr !== undefined ? `${campaignData.ctr}%` : '0%'}`}
              titles="CTR"
              tooltipValue={`Clicks: ${campaignData?.totalClick || 0} `}
            />
          </Grid>

          <Grid item size={{ xs: 4 }} className="d-grid ">
            <SummaryBox
              title="Total Spent"
              image={spendIcon}
              value={
                `${getCurrencySymbol(campaignData?.totalSpent?.currency)} ${numberFormatter(
                  campaignData?.totalSpent?.amount,
                )}` || 0
              }
              valuesData={
                `${getCurrencySymbol(campaignData?.totalSpent?.currency)} ${numberFormatter(
                  eCPC,
                )}` || 0
              }
              titles="eCPC"
              tooltipValue={
                `Total Spent: ${getCurrencySymbol(campaignData?.totalSpent?.currency)} ${
                  campaignData?.totalSpent?.amount
                }` || 0
              }
            />
          </Grid>
        </Grid>
      </div> */}
      {isLoading ? (
        <Loader />
      ) : (
        <Table
          keyField="id"
          tableData={tableData}
          tableHeader={getTableHeader}
          isRemote={true}
          onPageChangeFun={handleTableChange}
          totalSize={data?.totalElements}
          activePage={pageNumber}
          sizePerPage={pageLimit}
          wrapperClasses="scroll-visible line-item-table"
          defaultSorted={[
            {
              dataField: 'firstName',
              order: 'asc',
            },
          ]}
          onButtonClick={handleButtonClick}
          buttonLabel="Click here to new line item"
          additionalText="Create Line Item to proceed with the steps of getting your advertisement ready!"
        />
      )}
      <ConformationBox
        isOpen={!!deleteId}
        handleClose={() => setDeleteId()}
        handleSubmit={() => handleDelete(deleteId)}
        title="Delete ?"
        subtitle="Are you sure you want to delete this line item?"
        btnCloseLabel="Close"
        btnSubmitLabel="Delete"
      />
    </>
  );
};
