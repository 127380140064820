import React, { useEffect, useState, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NewTextField, Paper, Grid, NewSelect, Button, Typography } from '../../common';
import history from '../../history';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Services from '../../service-utils/services';
import { useQuery, useMutation } from 'react-query';
import queryString from 'query-string';
import { CACHE_TIME, STALE_TIME } from './../../common/constants';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import getSessionData from '../../service-utils/session-util';
import { orderBy } from 'lodash';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setHeader } from '../../libs/redux-sdk/actions';
import { CONTENT_URL } from './../../common/constants';
import { useLocation } from 'react-router-dom';
import { ConformationBox } from '../../components/conformationBox';
import { handleMsgOnForm } from '../../common/utils';
import OverlapLoader from '../../common/loader/OverlapLoader';

const ViewVideoCreative = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isClosed = searchParams.get('isClosed');
  const { creativeId = '' } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { agencyId = '', tncUpdated } = getSessionData();
  const [languageOptions, setLanguageOptions] = useState([]);

  const [attributesOptions, setAttributesOptions] = useState([]);
  const [mimeOptions, setMimeOptions] = useState([]);
  const [isSecure, setIsSecure] = useState(true);
  const [isSkippable, setIsSkippable] = useState(true);
  const [imageUrl, setImageUrl] = useState();
  const [checked, setChecked] = useState({});
  const [isPublishedValue, setIsPublishedValue] = useState(false);
  const [serverErrors, setServerErrors] = useState({});
  const [conformation, setConformation] = useState();
  const [isError, setIsError] = useState(false);
  const { advertiserId } = getSessionData();

  // Set header with back button
  useEffect(() => {
    dispatch(
      setHeader({
        back: {
          text: 'Back',
          action: () => history.goBack(),
        },
      }),
    );
  }, [dispatch]);

  // Fetch all advertisers
  const { data: allAdvertiser = [] } = useQuery(
    ['ALL_ADVERTISERS', agencyId],
    async () => {
      const payload = {
        pageSize: 5000,
        pageNum: 0,
        enabled: true,
        agencyId,
      };
      const response = await Services.advertisersGetAll(queryString.stringify(payload));
      return response.data?.advertiserList;
    },
    { enabled: !!agencyId },
  );

  const { data: languages = {} } = useQuery(
    ['MASTER_LANGUAGE', agencyId],
    async () => {
      const payload = { agencyId };
      const response = await Services.getMasterLanguages(queryString.stringify(payload));
      return response.data?.masterList;
    },
    { enabled: !!agencyId, staleTime: STALE_TIME.HALF_HOUR, cacheTime: CACHE_TIME.HALF_HOUR },
  );

  const { data: mimeTypes = {} } = useQuery(
    ['MASTER_MIME_TYPES', agencyId],
    async () => {
      const payload = { agencyId };
      const response = await Services.getMasterMimeTypes(queryString.stringify(payload));
      return response.data?.masterList;
    },
    { enabled: !!agencyId, staleTime: STALE_TIME.HALF_HOUR, cacheTime: CACHE_TIME.HALF_HOUR },
  );

  const { data: attrCheckboxOptions = {} } = useQuery(
    ['MASTER_CREATIVE_ATTRIBUTES', agencyId],
    async () => {
      const payload = { agencyId };
      const response = await Services.getMasterCreativeAttr(queryString.stringify(payload));
      return response.data?.masterList;
    },
    { enabled: !!agencyId, staleTime: STALE_TIME.HALF_HOUR, cacheTime: CACHE_TIME.HALF_HOUR },
  );

  const { data: creativeData = {} } = useQuery(
    ['CREATIVE_DATA', creativeId, agencyId, advertiserId],
    async () => {
      const payload = { agencyId };
      const response = await Services.creativeGetDetails(
        advertiserId,
        creativeId,
        queryString.stringify(payload),
      );
      return response.data?.creativeDTO;
    },
    { enabled: !!creativeId && !!agencyId },
  );

  const { mutate: creativeUpdate, isLoading: isLoadingCreativeUpdate } = useMutation(
    async (payload) => {
      const query = queryString.stringify({ agencyId });
      const response = await Services.creativeUpdate(advertiserId, creativeId, query, payload);
      return response.data;
    },
    {
      onError: (error) => {
        // Check if there are specific field errors in the response
        if (error?.response?.data?.errors) {
          const fieldErrors = {};

          // Map each error to the respective field
          error.response.data.errors.forEach((err) => {
            if (err.reasonDesc && err.reasonCode) {
              fieldErrors[err.reasonDesc] = err.reasonCode;
            }
          });
          setServerErrors(fieldErrors);
        } else {
          // General error message if no specific errors
          enqueueSnackbar('Failed to update creative. Please try again.', {
            variant: 'error',
          });
        }
      },
      onSuccess: (data, payload) => {
        // Clear any errors and show success message
        setServerErrors({});
        enqueueSnackbar('Creative updated successfully.', {
          variant: 'success',
        });
        history.push(`/advertiser/creative?advertiserId=${advertiserId}`);
      },
    },
  );

  const handleOnSubmit = (values, isPublished = false) => {
    // Create payload for update
    let payload = {
      advertiserId,
      name: values?.creativeName,
      masterCreativeAttributes: Object.keys(checked)
        .filter((key) => checked[key])
        .map((i) => parseInt(i)),
      published: isPublished,
    };

    if (creativeData.id) {
      payload.id = creativeData.id;
    }

    // Add video data
    payload.videoDTO = {
      duration: values.duration,
      landingPageUrl: values.landingPageUrl,
      externalImpressionTrackerUrl: values.externalImpressionTrackerUrl,
      thumbnailUrl: values.thumbnailUrl,
      languageId: values.language?.value,
      mimeTypeId: values.mimeTypes?.length ? values.mimeTypes[0]?.value : null,
      secure: isSecure,
      skippAble: isSkippable,
      videoType: 'UPLOADED',
    };

    creativeUpdate(payload);
  };

  // Setup Formik
  const { handleSubmit, values, touched, errors, setFieldValue } = useFormik({
    initialValues: {
      creativeName: '',
      advertiser: {},
      language: {},
      mimeTypes: '',
      landingPageUrl: '',
      externalImpressionTrackerUrl: '',
      thumbnailUrl: '',
      uploadedVideoUrl: '',
      vastTag: '',
      vastVersion: '',
      vastWrapper: '',
      videoType: {},
    },
    validationSchema: Yup.object({
      creativeName: Yup.string().max(255).required('Name is required.'),
      landingPageUrl: Yup.string().max(255).required('landing page url is required.'),
      duration: Yup.string().max(255).required('video duration is required.'),
    }),
    onSubmit: () => handleOnSubmit(values, false),
  });

  // Setup language options
  useEffect(() => {
    if (!isEmpty(languages)) {
      const data = languages.map((item) => ({
        ...item,
        value: item.id,
      }));
      setLanguageOptions(data);
      setFieldValue('language', data[0]);
    }
  }, [languages, setFieldValue]);

  // Setup mime type options
  useEffect(() => {
    if (!isEmpty(mimeTypes)) {
      const data = mimeTypes.map((item) => ({
        ...item,
        value: item.id,
      }));
      setMimeOptions(data);
      setFieldValue('mimeTypes', [data[0]]);
    }
  }, [mimeTypes, setFieldValue]);

  // Setup attributes options for checkboxes
  useEffect(() => {
    if (!isEmpty(attrCheckboxOptions)) {
      const data = attrCheckboxOptions.map((item) => ({
        ...item,
        value: item.id,
        name: item.label,
      }));
      setAttributesOptions(data);
    }
  }, [attrCheckboxOptions]);

  // Format advertiser options
  const allAdvertisersOptions = useMemo(() => {
    return (
      orderBy(
        allAdvertiser?.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
          disabled: item.status,
        })),
        [(advertiser) => advertiser.label.toLowerCase()],
        ['asc'],
      ) || []
    );
  }, [allAdvertiser]);

  // Handle checkbox change
  const handleCheckboxChange = (event, id) => {
    setChecked((state) => ({ ...state, [id]: event.target.checked }));
  };

  // Set creative data when loaded
  useEffect(() => {
    const {
      name,
      advertiserId,
      published,
      masterCreativeAttributes = [],
      videoDTO,
    } = creativeData || {};

    if (!isEmpty(creativeData)) {
      // Set basic fields
      name && setFieldValue('creativeName', name);
      published && setIsPublishedValue(published);
      advertiserId &&
        setFieldValue(
          'advertiser',
          allAdvertisersOptions.find((item) => item.value === advertiserId),
        );

      // Set checkbox values
      if (!isEmpty(masterCreativeAttributes) && !isEmpty(attrCheckboxOptions)) {
        let checkedData = {};
        for (var i = 0; i < masterCreativeAttributes.length; i++) {
          checkedData = { ...checkedData, [masterCreativeAttributes[i]]: true };
        }
        setChecked(checkedData);
      }

      // Set video-specific fields
      if (!isEmpty(videoDTO)) {
        // setSelectedCreative('video');
        setIsSecure(Boolean(videoDTO.secure));
        setIsSkippable(Boolean(videoDTO.skippAble));
        videoDTO.landingPageUrl && setFieldValue('landingPageUrl', videoDTO.landingPageUrl);
        videoDTO.externalImpressionTrackerUrl &&
          setFieldValue('externalImpressionTrackerUrl', videoDTO.externalImpressionTrackerUrl);

        if (videoDTO.languageId && !isEmpty(languageOptions)) {
          const selectedLanguage = languageOptions.find(
            (item) => item.value === videoDTO.languageId,
          );
          if (selectedLanguage) {
            setFieldValue('language', selectedLanguage);
          }
        }

        if (videoDTO.mimeTypeId && !isEmpty(mimeOptions)) {
          const selectedMimeType = mimeOptions.find((item) => item.value === videoDTO.mimeTypeId);
          if (selectedMimeType) {
            setFieldValue('mimeTypes', [selectedMimeType]);
          } else {
            // Try to find in constant VIDEO_MIME_TYPES
            const constantMimeType = mimeTypes.find((item) => item.value === videoDTO.mimeTypeId);
            if (constantMimeType) {
              setFieldValue('mimeTypes', constantMimeType);
            }
          }
          videoDTO?.duration && setFieldValue('duration', videoDTO?.duration);
        }

        // if (videoDTO.bannerSizeId && !isEmpty(bannerSizeOptions)) {
        //   const selectedBannerSize = bannerSizeOptions.find(
        //     (item) => item.value === videoDTO.bannerSizeId,
        //   );
        //   if (selectedBannerSize) {
        //     setFieldValue('banner', selectedBannerSize);
        //   }
        // }

        videoDTO.uploadedVideoUrl && setImageUrl(videoDTO?.uploadedVideoUrl);
      }
    }
  }, [
    creativeData,
    setFieldValue,
    languageOptions,
    attrCheckboxOptions,
    allAdvertisersOptions,
    mimeOptions,
  ]);

  const tncLoader = tncUpdated === 'true' && isLoadingCreativeUpdate;

  return (
    <div className="d-flex flex-column justify-content-center align-items-center mb-5">
      {imageUrl && !!creativeData.published ? (
        <Paper className="p-2">
          <video width="500px" height="auto" controls>
            <source src={`${CONTENT_URL}/${imageUrl}`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Paper>
      ) : null}
      <div className="col-md-10 bg-white rounded shadow m-auto mb-4 mt-3 p-0">
        <Paper className="pt-3 p-4 mn-h-200">
          <div className="d-flex justify-content-center mb-3">
            <Typography color="textPrimary" variant="h5">
              Creative Information
            </Typography>
          </div>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} rowSpacing={0.1}>
              <div className="creative-section border p-3 m-2">
                <div className="creative-title">Video</div>
                <Grid className="row" rowSpacing={0.1} spacing={3}>
                  <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
                    <NewTextField
                      required
                      fullWidth
                      label="Name"
                      margin="normal"
                      name="creativeName"
                      variant="outlined"
                      error={Boolean(touched.creativeName && errors.creativeName)}
                      helperText={touched.creativeName && errors.creativeName}
                      value={values.creativeName}
                      onChange={(event) => setFieldValue('creativeName', event.target.value)}
                      disabled={isPublishedValue}
                    />
                    {serverErrors.creativeName && (
                      <p className="text-danger mt-n2">{serverErrors.creativeName}</p>
                    )}
                  </Grid>
                  <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
                    <NewSelect
                      required
                      options={mimeOptions}
                      value={values.mimeTypes}
                      onChange={(obj) => setFieldValue('mimeTypes', obj)}
                      placeholder={`Video Format`}
                      className="py-2"
                      disabled={creativeId}
                      isOptionDisabled={() => creativeId}
                    />
                    {serverErrors.mimeTypes && (
                      <p className="text-danger mt-n2">{serverErrors.mimeTypes}</p>
                    )}
                  </Grid>

                  <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
                    <NewSelect
                      options={languageOptions}
                      value={values.language}
                      onChange={(obj) => setFieldValue('language', obj)}
                      placeholder={`Language`}
                      className="py-2"
                      disabled={isPublishedValue}
                      isOptionDisabled={() => isPublishedValue}
                    />
                    {serverErrors.language && (
                      <p className="text-danger mt-n2">{serverErrors.language}</p>
                    )}
                  </Grid>
                  <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
                    <NewTextField
                      required
                      fullWidth
                      label="Video Duration"
                      margin="normal"
                      name="Video Duration"
                      variant="outlined"
                      error={Boolean(touched.duration && errors.duration)}
                      helperText={touched.duration && errors.duration}
                      value={values.duration}
                      onChange={(event) => setFieldValue('duration', event.target.value)}
                      disabled={isPublishedValue}
                      type="number"
                      info="Duration should be in seconds."
                    />
                    {serverErrors.duration && (
                      <p className="text-danger mt-n2">{serverErrors.duration}</p>
                    )}
                  </Grid>
                </Grid>
                <Grid item size={{ xs: 12 }} className="py-0">
                  <NewTextField
                    fullWidth
                    label={'External Impression Tracker Url'}
                    margin="normal"
                    name="externalImpressionTrackerUrl"
                    variant="outlined"
                    error={Boolean(
                      touched.externalImpressionTrackerUrl && errors.externalImpressionTrackerUrl,
                    )}
                    helperText={
                      touched.externalImpressionTrackerUrl && errors.externalImpressionTrackerUrl
                    }
                    value={values.externalImpressionTrackerUrl}
                    onChange={(event) => {
                      setFieldValue('externalImpressionTrackerUrl', event.target.value);
                    }}
                    disabled={isPublishedValue}
                  />{' '}
                  {serverErrors.externalImpressionTrackerUrl && (
                    <p className="text-danger mt-n2">{serverErrors.externalImpressionTrackerUrl}</p>
                  )}
                </Grid>
                <Grid item size={{ xs: 12 }} className="py-0">
                  <NewTextField
                    fullWidth
                    required
                    label="Landing Page Url"
                    margin="normal"
                    name="landingPageUrl"
                    variant="outlined"
                    error={Boolean(touched.landingPageUrl && errors.landingPageUrl)}
                    helperText={touched.landingPageUrl && errors.landingPageUrl}
                    value={values.landingPageUrl}
                    onChange={(event) => {
                      setFieldValue('landingPageUrl', event.target.value);
                    }}
                    disabled={isPublishedValue}
                    // info="landing page url"
                  />
                  {serverErrors.landingPageUrl && (
                    <p className="text-danger mt-n2">{serverErrors.landingPageUrl}</p>
                  )}
                </Grid>
                <Grid container spacing={3} className="mb-2">
                  <Grid item size={{ xs: 13, sm: 3 }} className="py-0 mt-2">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isSecure}
                          onChange={(e) => setIsSecure(e.target.checked)}
                          name="secure"
                          disabled={isPublishedValue}
                        />
                      }
                      label="Is Secure"
                    />
                  </Grid>
                  <Grid item size={{ xs: 12, sm: 3 }} className="py-0 mt-2">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isSkippable}
                          onChange={(e) => setIsSkippable(e.target.checked)}
                          name="skippable"
                          disabled={isPublishedValue}
                        />
                      }
                      label="Is Skippable"
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>

            {/* Creative Attributes Checkboxes */}
            <Grid item size={{ xs: 12 }} className="py-0 m-3">
              {/* <Typography variant="subtitle1" className="mb-2">
                Creative Attributes
              </Typography> */}
              <FormGroup className="row flex-row">
                {attributesOptions.map((item) => (
                  <FormControlLabel
                    key={item.id}
                    className="col-4 mb-2 mr-0 p-0"
                    control={
                      <Checkbox
                        checked={!!checked[item.id]}
                        onChange={(e) => handleCheckboxChange(e, item.id)}
                        name={item.name}
                        disabled={isPublishedValue}
                      />
                    }
                    label={item.name}
                  />
                ))}
              </FormGroup>
            </Grid>

            <div className="mt-4 d-flex justify-content-end">
              {isClosed ? (
                <Button
                  variant="contained"
                  className="btn btn-secondary d-flex"
                  onClick={window.close}
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className="btn btn-secondary d-flex"
                  onClick={history.goBack}
                >
                  Cancel
                </Button>
              )}
              {!isPublishedValue && (
                <Button variant="contained" type="submit" className="btn btn-primary d-flex ml-3">
                  Save
                </Button>
              )}
              {creativeId && !isPublishedValue && (
                <Button
                  variant="contained"
                  className="btn btn-primary d-flex ml-3"
                  disabled={(isPublishedValue && creativeId) || isError || isEmpty(imageUrl)}
                  onClick={(e) => {
                    e.preventDefault();
                    setConformation(true);
                  }}
                >
                  Publish
                </Button>
              )}
            </div>
          </form>
        </Paper>
      </div>

      {/* Confirmation Dialog */}
      <ConformationBox
        isOpen={!!conformation}
        handleClose={() => setConformation()}
        handleSubmit={() => {
          handleOnSubmit(values, true); // Pass publish flag
          setConformation(false);
        }}
        title="confirm ?"
        subtitle="Are you sure you want to Publish this creative? Once publish it cannot be rollback."
        btnCloseLabel="Close"
        btnSubmitLabel="confirm"
      />

      {/* Loading Overlay */}
      {tncLoader && <OverlapLoader />}
    </div>
  );
};

export default ViewVideoCreative;
