import React, { useEffect, useState } from 'react';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';
import { getSessionData } from '../../service-utils';
import { Image, SquarePlay } from 'lucide-react';

function ObjectiveSelection({ payload = {}, timezone, goToNextStep }) {
  const { campaignId = '', lineItemId = '', agencyId = '' } = payload;

  const history = useHistory();
  const dispatch = useDispatch();
  const { advertiserId } = getSessionData();
  const [selectedType, setSelectedType] = useState('BANNER');

  const handleTypeSelection = (type) => {
    setSelectedType(type);
  };
  const handleSubmit = () => {
    if (selectedType) {
      goToNextStep(selectedType); // Pass the selected card data to the next step
    }
  };

  return (
    <div className="col-md-10 bg-white rounded shadow m-auto mb-4 p-5">
      <div className="p-5">
        <div className="d-flex justify-content-center">
          <Card
            className={`m-2 text-center ${selectedType === 'BANNER' ? 'border-primary' : ''}`}
            style={{
              width: '400px',

              cursor: 'pointer',
              padding: '16px',
              borderRadius: '15px',
              border: selectedType === 'BANNER' ? '2px solid #dd6633' : '1px solid #ddd',
              boxShadow:
                selectedType === 'BANNER'
                  ? '0 4px 10px rgba(63, 81, 181, 0.3)'
                  : '0 2px 6px rgba(0, 0, 0, 0.1)',
              transition: 'box-shadow 0.3s ease',
              // backgroundColor: selectedType === 'BANNER' ? '#f5f5f5' : '#fff',
              textAlign: 'center',
            }}
            onClick={() => handleTypeSelection('BANNER')}
          >
            <Card.Body>
              <Image className="primary-color mb-2" size={35} />
              <Card.Title className="mt-2" style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                Display Ads
              </Card.Title>
              {
                <Card.Text className="mt-3">
                  Choose Display Ads for static or animated banners that appear across websites and
                  apps.
                </Card.Text>
              }
            </Card.Body>
          </Card>

          <Card
            // disabled={true}
            className={`m-2 text-center ${selectedType === 'VIDEO' ? 'border-primary' : ''}`}
            style={{
              width: '400px',

              cursor: 'pointer',
              padding: '16px',
              borderRadius: '15px',
              border: selectedType === 'VIDEO' ? '2px solid #dd6633' : '1px solid #ddd',
              boxShadow:
                selectedType === 'VIDEO'
                  ? '0 4px 10px rgba(63, 81, 181, 0.3)'
                  : '0 2px 6px rgba(0, 0, 0, 0.1)',
              transition: 'box-shadow 0.3s ease',
              // backgroundColor: selectedType === 'VIDEO' ? '#f5f5f5' : '#fff',
              textAlign: 'center',
            }}
            onClick={() => handleTypeSelection('VIDEO')}
          >
            <Card.Body>
              <SquarePlay className="primary-color mb-2" size={35} />
              <Card.Title
                className="mt-2 display-5 "
                style={{ fontSize: '1.2rem', fontWeight: 'bold' }}
              >
                Video
              </Card.Title>

              <Card.Text className="mt-3">
                Select Video Ads for immersive storytelling and high engagement with users.
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="mt-4 d-flex justify-content-end">
        <Button variant="primary" onClick={handleSubmit} disabled={!selectedType}>
          Continue
        </Button>
      </div>
    </div>

    // const { lineItemId = '', agencyId = '' } = payload;
    // const { enqueueSnackbar } = useSnackbar();
    // const [selectedCard, setSelectedCard] = useState(null); // Tracks selected card
    // const [cardData, setCardData] = useState();

    // const { data: objectiveData = [], isLoading } = useQuery(
    //   ['OBJECTIVE_DATA', agencyId],
    //   async () => {
    //     const response = await Services.getMasterObjectives(agencyId);
    //     return response?.data?.masterObjectiveList || [];
    //   },
    //   { enabled: !!agencyId },
    // );

    // useEffect(() => {
    //   if (objectiveData.length) {
    //     const defaultObjective = objectiveData.find((item) => item.id === 1);
    //     if (defaultObjective) {
    //       setSelectedCard(defaultObjective.id);
    //     }
    //   }
    // }, [objectiveData]);

    // const handleObjectiveChange = (id) => {
    //   setSelectedCard(id);
    //   const selected = objectiveData.find((item) => item.id === id);
    //   setCardData(selected);
    // };

    // const handleSubmit = () => {
    //   if (cardData) {
    //     goToNextStep(cardData); // Pass the selected card data to the next step
    //   } else {
    //     enqueueSnackbar('Please select an objective before continuing.', { variant: 'warning' });
    //   }
    // };

    // return (
    //   <>
    //     {isLoading ? (
    //       <Loader />
    //     ) : (
    //       <div className="p-4">
    //         <div className="d-flex justify-content-center mb-3">
    //           <Typography color="textPrimary" variant="h5">
    //             Choose campaign objective
    //           </Typography>
    //         </div>

    //         <Grid container spacing={3}>
    //           {objectiveData.map((item) => (
    //             <Grid item size={{ xs: 12, sm: 4 }} key={item.id}>
    //               <Card
    //                 onClick={() => handleObjectiveChange(item.id)}
    //                 style={{
    //                   cursor: 'pointer',
    //                   padding: '16px',
    //                   borderRadius: '15px',
    //                   border: selectedCard === item.id ? '2px solid #dd6633' : '1px solid #ddd',
    //                   boxShadow:
    //                     selectedCard === item.id
    //                       ? '0 4px 10px rgba(63, 81, 181, 0.3)'
    //                       : '0 2px 6px rgba(0, 0, 0, 0.1)',
    //                   transition: 'box-shadow 0.3s ease',
    //                   backgroundColor: selectedCard === item.id ? '#f5f5f5' : '#fff',
    //                   textAlign: 'center',
    //                 }}
    //               >
    //                 <CardContent>
    //                   <Typography
    //                     variant="subtitle1"
    //                     style={{
    //                       fontWeight: 'bold',
    //                       color: selectedCard === item.id ? '#3f51b5' : '#333',
    //                     }}
    //                   >
    //                     {item.label}
    //                   </Typography>

    //                   <Typography variant="body2" color="textSecondary">
    //                     {item.description}
    //                   </Typography>
    //                   {/* <Typography variant="body2" color="textSecondary">
    //                     CPM:{' '}
    //                     {agencyCurrency === 'INR'
    //                       ? `${getCurrencySymbol(agencyCurrency)} ${item.cpmInr}`
    //                       : `${getCurrencySymbol(agencyCurrency)} ${item.cpm}`}
    //                   </Typography>
    //                   <Typography variant="body2" color="textSecondary">
    //                     CTR: {item.ctr.toFixed(2)}%
    //                   </Typography> */}
    //                 </CardContent>
    //               </Card>
    //             </Grid>
    //           ))}
    //         </Grid>

    //         <div className="mt-4 d-flex justify-content-end ">
    //           <Button
    //             disabled={!selectedCard || lineItemId} // Disable button if no objective is selected
    //             variant="contained"
    //             type="submit"
    //             className="btn btn-primary d-flex ml-4"
    //             onClick={handleSubmit}
    //           >
    //             Continue
    //           </Button>
    //         </div>
    //       </div>
    //     )}
    //   </>
  );
}

export default ObjectiveSelection;
