import React, { useState, useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const Root = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
});

const DropZone = styled('div')(({ theme, isDragging }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  border: '2px dashed #dd6633',
  borderRadius: '8px',
  padding: theme.spacing(3),
  textAlign: 'center',
  cursor: 'pointer',
  backgroundColor: '#f9f9f9',
  '&:hover': {
    backgroundColor: '#f3f3f3',
  },
  width: '100%',
  maxWidth: '750px',
}));

const UploadIcon = styled(CloudUploadIcon)({
  fontSize: '48px',
  color: '#dd6633',
});
const Input = styled('input')({
  display: 'none',
});

const ErrorText = styled('p')({
  color: 'red',
  marginTop: '8px',
});

export const DragDropVideo = ({
  desc = 'Upload a video file (MP4, WebM, MOV)',
  accept = 'video/mp4, video/webm, video/mov',
  acceptedFileTypes = ['mp4', 'webm', 'mov'],
  fileTypeErrorMessage = 'Invalid file type. Please upload a valid video file.',
  getImage = () => {},
  disabled = false,
  inputFileRef,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [fileTypeError, setFileTypeError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [dragging, setDragging] = useState(false);

  const temp = useRef(null);
  const inputFile = inputFileRef || temp;

  useEffect(() => {
    return () => {
      if (preview) URL.revokeObjectURL(preview);
    };
  }, [preview]);

  const validateFile = (file) => {
    return new Promise((resolve, reject) => {
      if (!file) {
        reject(new Error('No file selected'));
        return;
      }

      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (!acceptedFileTypes.includes(fileExtension)) {
        reject(new Error(fileTypeErrorMessage));
      } else if (file.size > 300 * 1024 * 1024) {
        reject(new Error('Video file size should be less than 300MB.'));
      } else {
        resolve(file);
      }
    });
  };

  const handleFileSelection = async (file) => {
    if (!file) return;

    try {
      const validFile = await validateFile(file);
      setFileTypeError(false);
      setErrorMsg('');
      setSelectedFile(validFile);

      const previewURL = URL.createObjectURL(validFile);
      setPreview(previewURL);

      getImage(validFile);
    } catch (error) {
      setFileTypeError(true);
      setErrorMsg(error.message);
      setSelectedFile(null);
      setPreview(null);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (!disabled) {
      handleFileSelection(e.dataTransfer.files[0]);
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    if (!disabled) {
      setDragging(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const onSelectFile = (e) => {
    handleFileSelection(e.target.files[0]);
  };

  const handleClick = () => {
    if (!disabled && inputFile.current) {
      inputFile.current.click();
    }
  };

  return (
    <Root>
      <DropZone
        className={fileTypeError ? 'file-type-error' : ''}
        isDragging={dragging}
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onClick={handleClick}
        style={disabled ? { opacity: 0.6, cursor: 'not-allowed' } : {}}
      >
        <UploadIcon />
        <p>Drag & drop your video here</p>
        <p>{desc}</p>
        {errorMsg && <ErrorText>{errorMsg}</ErrorText>}
      </DropZone>
      <Input
        ref={inputFile}
        type="file"
        accept={accept}
        onChange={onSelectFile}
        disabled={disabled}
      />
    </Root>
  );
};

export default DragDropVideo;
